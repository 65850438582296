<template>
  <v-card flat>
    <v-toolbar
        dense
        elevation="1"
        class="mb-7"
    >
      <v-toolbar-title>{{ form.firstName }} {{ form.lastName }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="black"
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              v-for="(item, i) in items"
              :key="i"
              link
          >
            <v-list-item-title @click="onMenuClick(i)">{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text>
      <v-form ref="form">
        <TextField
            class="mb-lg-10 mb-5"
            :placeholder="$t('profile.firstName')"
            v-model="form.firstName"
            :label="$t('profile.firstName')"
            :persistent-placeholder="true"
            required
        />
        <TextField
            class="mb-lg-10 mb-5"
            :placeholder="$t('profile.lastName')"
            :label="$t('profile.lastName')"
            v-model="form.lastName"
            required
        />
        <TextField
            :placeholder="$t('profile.email')"
            :label="$t('profile.email')"
            v-model="form.email"
            required
        />
        <div v-if="form.emailConfirm" class="mb-lg-10 mb-5" style="font-size: 1rem">Confirmed</div>
        <div v-if="!form.emailConfirm" class="mb-lg-10 mb-5" style="font-size: 1rem">Not Confirmed</div>

        <TextField
            class="mb-lg-10 mb-5"
            :placeholder="$t('profile.phone')"
            :label="$t('profile.phone')"
            v-model="form.phone"
            required
        />
        <TextField
            class="mb-lg-10 mb-5"
            :placeholder="$t('profile.address')"
            :label="$t('profile.address')"
            v-model="form.address"
            required
        />
        <TextField
            class="mb-lg-10 mb-5"
            :placeholder="$t('profile.city')"
            :label="$t('profile.city')"
            v-model="form.city"
            required
        />
        <TextField
            class="mb-lg-10 mb-5"
            :placeholder="$t('profile.state')"
            :label="$t('profile.state')"
            v-model="form.state"
            required
        />
        <TextField
            class="mb-lg-10 mb-5"
            :placeholder="$t('profile.zip')"
            :label="$t('profile.zip')"
            v-model="form.zip"
            required
        />
        <v-select
            outlined
            :label="$t('profile.type')"
            v-model="form.type"
            :items="types"
        ></v-select>
        <div class="mb-lg-10 mb-5" style="font-size: 1rem">Stripe ID: {{ user.stripeId }}</div>
        <v-row>
          <v-col>
            <v-btn
                type="button"
                class="btn mb-15"
                block
                color="secondary"
                @click="onClick"
            >
              <span class="btn__text btn__text--white"> {{ $t('accountPage.update') }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-dialog width="450" v-model="dialogPasswd">
      <v-form @submit.prevent="submit" lazy-validation v-model="valid" ref="form">
        <v-card>
          <v-card-title>Change password</v-card-title>
          <v-card-text>
            <TextField
                type="password"
                v-model="passModel.pass"
                label="Password"
                :rules="[v => !!v || 'Password is required']"
                ref="pass"
            />
          </v-card-text>
          <v-card-text>
            <TextField
                type="password"
                v-model="passModel.passConfirm"
                :rules="[v => !!v || 'Confirm password is required']"
                label="Confirm password"
                ref="passConfirm"
            />
          </v-card-text>
          <AlertError :error="errorMsg" />
          <v-card-actions>
            <v-btn color="primary" @click="onPassSubmit">Update password</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-card>
</template>

<script>
import TextField from '@/components/TextField';
import AlertError from '@/components/AlertError';

export default {
  name: 'AdminUserForm',
  components: {
    TextField,
    AlertError
  },
  props: {
    user: {
      type: Object,
      default:  () => ({ email: '', address: '' })
    }
  },
  data() {
    return {
      valid: false,
      form: {},
      types: ['boss', 'jammer', 'admin'],
      dialogPasswd: false,
      errorMsg: '',
      passModel: {
        pass: '',
        passConfirm: ''
      },
      items: [
        { title: 'Switch to this user' },
        { title: 'Change password' }
      ],
    }
  },
  methods: {
    onClick() {
      let sub = {...this.form};
      this.$emit('submit', sub);
    },
    onMenuClick(evt) {
      switch (evt) {
        case 0:
          this.$emit('suid');
          break;
        case 1:
          this.dialogPasswd = true
          break;
      }
    },
    onPassSubmit() {
      if (this.$refs.form.validate()) {
        if (this.passModel.pass !== this.passModel.passConfirm) {
          this.errorMsg = 'Passwords do not match';
        } else {
          this.errorMsg = '';
          this.$emit('password', { pass: this.passModel.pass, userId: this.form._id });
          this.dialogPasswd = false;
        }
      }
    }
  },
  watch: {
    user: {
      immediate: true,
      handler: function(userFromProps){
        if (userFromProps) {
          this.form = {
            ...userFromProps
          }
        }
      }
    }
  }
}
</script>