<template>
  <v-card>
    <v-card-title class="secondary white--text text-h5">
      Users Admin
      <v-spacer></v-spacer>
      <v-form @submit.prevent="search">
        <v-text-field color="#ffffff" :dark="true" v-model="strSearch">
          <v-icon
              slot="append"
              color="#ffffff"
          >
            mdi-magnify
          </v-icon>
        </v-text-field>
      </v-form>
    </v-card-title>
    <v-row
        class="pa-4"
        justify="space-between"
    >
      <v-col cols="12" lg="5" sm="12">

        <v-divider></v-divider>
        <v-list>
          <v-list-item-group
              v-model="sel"
              @change="onGroupClick"
          >
            <template v-for="(item, index) in users">
              <v-list-item :key="item.name">
                <v-list-item-content>
                  <v-list-item-title v-text="`${item.firstName} ${item.lastName}`">{{index}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col
          class="d-flex flex-column">
        <v-scroll-y-transition mode="out-in">
          <div
              v-if="!selected"
              class="text-h6 grey--text text--lighten-1 font-weight-light pt-6 mx-auto"
              style="align-self: center;"
          >
            Select a user
          </div>
          <AdminUserForm
              :user="selected"
              @submit="onSubmit"
              @suid="onSuid"
              @password="onPassword"
              v-else
          />
        </v-scroll-y-transition>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Http from '@/api/Http';
import { mutationNames } from '@/store/global';
import AdminUserForm from '@/components/admin/users/AdminUserForm'

export default {
  name: 'AdminUsersView.vue',
  components: { AdminUserForm },
  data () {
    return {
      api: Http.use('data'),
      sel: null,
      selected: null,
      users: [],
      strSearch: '',
      items: [
        { title: 'Switch to this user' }
      ],
    }
  },
  created () {
    this.search();
  },
  methods: {
    onSearchSubmit() {
      console.log('search');
    },
    onSubmit(user) {
      this.api.patch('admin/user/update/' + user._id, user)
          .then(res => {
            console.log(res);
            this.$emit('notify', {
              visible: true,
              color: 'success',
              text: this.$t('profile.updated'),
              icon: 'mdi-check-circle'
            });
          })
          .catch(err => {
            console.log(err);
          });
    },
    onPassword(pass) {
      this.api.post('/admin/user/passwd', pass)
          .then(() => {
            this.$emit('notify', {
              visible: true,
              color: 'success',
              text: this.$t('profile.passChg'),
              icon: 'mdi-check-circle'
            });
          })
          .catch(err => {
            this.$emit('notify', {
              visible: true,
              color: 'red accent-2',
              text: err.response.data.error ? err.response.data.error : err.message,
              icon: 'mdi-alert-circle-outline'
            });
          });
      console.log(pass);
    },
    onSuid() {
      if (this.selected._id === this.$store.state.user._id) {
        this.$emit('notify', {
          visible: true,
          color: 'red accent-2',
          text: 'You cannot switch to being yourself.',
          icon: 'mdi-alert-circle-outline'
        });
      } else {
        this.api.post('/auth/suid', {id: this.selected._id})
            .then(res => {
              this.$store.commit(mutationNames.UPDATE_CURRENT_USER, res.user);
              this.$router.push('/jams');
            })
            .catch(err => {
              this.$emit('notify', {
                visible: true,
                color: 'red accent-2',
                text: err.response.data.error ? err.response.data.error : err.message,
                icon: 'mdi-alert-circle-outline'
              });
            });
      }
    },
    onGroupClick(evt) {
      if (evt !== null) {
        this.selected = this.users[evt];
      } else {
        this.selected = null;
      }
    },
    search() {
      this.api.get('/admin/user/search', {search: this.strSearch})
          .then(users => {
            this.users = users;
          })
          .catch(err => {
            console.log(err);
          });
    }
  }
}
</script>

<style scoped>

</style>